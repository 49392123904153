

const getBaseUrl = () => {
    if(window?.location?.origin){
        var base_url = window.location.origin;

        var apiUrl = `${base_url}/fcb/fcb-api/api`;
        return apiUrl;
    }
    else{
        return "";
    }
}


const appConfig = {
    pathUserTemplate: 'https://eventqiu.com/assets/template/agm_user_template.xlsx',
    pathCodeTemplate: 'https://eventqiu.com/assets/template/agm_code_template.xlsx',
    apiPrefix: 'https://fcb-api.eventqiu.com/api',
    // apiPrefix: getBaseUrl(),
    authenticatedEntryPath: '/admin-eventqiu/user',
    unAuthenticatedEntryPath: '/admin-eventqiu/sign-in',
    adminPath: '/admin-eventqiu',
    tourPath: '/',
    notFound: '/not-found',
    locale: 'en',
    enableMock: false,
}

export default appConfig

